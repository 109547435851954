<template>
  <div>
    <h5>All Accessory List</h5>
    <div class="row">
      <div v-for="(admin_scenario_accessory, index) in admin_scenario_accessories" :key="index" class="col-md-4">
        <div class="card">
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body position-relative overflow-hidden" @click="accessoryDetails(admin_scenario_accessory.id, index)" style="cursor: pointer;">
              <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ admin_scenario_accessory.name }}</div>
              <div class="text-uppercase mt-2">
                <a-tag color="red">Issue: {{ admin_scenario_accessory.issue }}</a-tag>
                <a-tag color="orange">Pending: {{ admin_scenario_accessory.pending }}</a-tag>
                <a-tag color="orange">Inprogress: {{ admin_scenario_accessory.inprogress }}</a-tag>
                <a-tag color="green">Solved: {{ admin_scenario_accessory.solved }}</a-tag>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
      <a-skeleton active :loading="loading"></a-skeleton>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: {},
  data() {
    return {
      admin_scenario_accessories: [],
      loading: false,
      spinning: false,
      delayTime: 500,
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      this.loading = true
      this.spinning = true
      apiClient.get('api/admin-scenario/accessory/codes')
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.admin_scenario_accessories = data.admin_scenario_accessories
        })
        .catch(error => {
          this.loading = false
          this.spinning = false
          console.log(error)
        })
    },
    accessoryDetails(adminScenarioAccessoryId, index) {
      this.$router.push({ name: 'adminScenarioIssueAccessoryAllDetails', params: { admin_scenario_accessory_id: adminScenarioAccessoryId } })
    },
  },
}
</script>

<style scoped>

</style>
